html {
  font-size: 62.5%;
  background: #ffffff;
  color: #313131;

  body {
    font-size: 16px; /* px fallback */
    font-size: 1.6rem; /* default font-size for document */
    line-height: 1.5; /* a nice line-height */
    min-height: 100vh;
    min-width: 100vw;
  }
}



